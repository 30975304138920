import { SEOProps } from "@hfc/shared/components/SEO/SEO"
import { NextSeoProps } from "next-seo"

const defaultTitle = "Howdy Games NFT on Polygon"
const defaultMetaDescription = `
Howdy! Honest Farmer Club NFT game on Polygon. 
Play in the Howdy Games, acquire new game items and earn rewards in game quests and LP farming. 
Join Honest Farmer Club play-to-earn gaming metaverse today!`

const getPageTypeSpecificSEOProps = (props: SEOProps) => {
	const { title, description, pageType, appendDefaultTitle = false } = props

	switch (pageType) {
		default: {
			const pageSpecificSEOProps: NextSeoProps = {}

			// Title
			if (title)
				pageSpecificSEOProps.title = `${title}${
					appendDefaultTitle ? ` | ${defaultTitle}` : ""
				}`
			else pageSpecificSEOProps.title = defaultTitle

			// Description
			if (description) pageSpecificSEOProps.description = description
			else pageSpecificSEOProps.description = defaultMetaDescription

			return pageSpecificSEOProps
		}
	}
}

export default getPageTypeSpecificSEOProps
