import Navbar from "./Navbar"


const Header = () => {

	return (
		<header className="fixed top-0 z-50 w-full">
			<Navbar />
		</header>
	)
}

export default Header
