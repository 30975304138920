// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import { BrowserTracing } from "@sentry/tracing"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
	dsn:
		SENTRY_DSN ||
		"https://8f9b92e5ab844c538f234c9cd62beefb@o1305959.ingest.sentry.io/6548069",
	integrations: [new BrowserTracing()],
	beforeSend(event, hint) {
		// Check if it is an exception, and if so, show the report dialog
		if (event.exception) {
			Sentry.showReportDialog({ eventId: event.event_id })
		}
		return event
	},
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1.0,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
})
