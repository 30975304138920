/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	AnchorHTMLAttributes,
	DetailedHTMLProps,
	Fragment,
	MouseEventHandler,
} from "react"
import Link from "next/link"
import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

import classNames from "@hfc/shared/utils/css/classNames"
import { useRouter } from "next/router"
// import getAddressPreview from "utils/blockchain/getAddressPreview"

interface NavbarItem {
	href: string
	name: string
	isExternal?: boolean
}
const navigation: NavbarItem[] = [
	{ name: "Home", href: "section-home" },
	{ name: "NFTs", href: "section-nft" },
	{ name: "Ecosystem", href: "section-farmecosystem" },
	{ name: "Roadmap", href: "section-roadmap" },
	{ name: "Team", href: "section-team" },
	{
		name: "Whitepaper",
		href:
			process.env.NEXT_PUBLIC_WHITEPAPER_URL ??
			"https://docs.farmernft.com/howdy-paper-2.0",
		isExternal: true,
	},
	{
		name: "Blogs",
		href:
			process.env.NEXT_PUBLIC_BLOGS_URL ??
			"https://honestfarmerclub.medium.com/",
		isExternal: true,
	},
]

// TODO: import as svg via webpack
// const metamaskImageUrl =
// 	"https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg"

const getNavListProps = (item: NavbarItem) => {
	const { name, isExternal = false } = item
	const { asPath, basePath } = useRouter()

	const href = isExternal ? item.href : `#${item.href}`

	const styles: DetailedHTMLProps<
		AnchorHTMLAttributes<HTMLAnchorElement>,
		HTMLAnchorElement
	> = {
		rel: "noopener noreferrer",
		target: "_blank",
	}

	const props: any = {
		name,
		href,
		styles,
	}

	if (!isExternal) {
		if (asPath?.includes("privacy-policy") || asPath?.includes("terms")) {
			props.href = `${basePath}/${href}`
		} else {
			const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
				const target = document.getElementById(item.href)
				e.preventDefault()
				target?.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
			props.onClick = onClick
		}
	} else {
		props.styles = styles
		props.target = "_blank"
		props.rel = "noopener noreferrer"
	}

	return props
}

const DesktopNavbar = () => {
	return (
		<div className="justify-center hidden w-full h-full space-x-2 bg-no-repeat bg-cover md:flex md:items-center lg:space-x-6">
			{navigation.map((item) => {
				const { name, href, ...props } = getNavListProps(item)
				return (
					<a
						key={name}
						href={href}
						className={classNames(
							"text-white text-lg font-normal hover:bg-farm-black hover:bg-opacity-50 hover:text-farm-yellow",
							"px-3 py-2 rounded-md font-medium font-heading text-sm xl:text-2xl",
						)}
						{...props}
					>
						{name}
					</a>
				)
			})}
		</div>
	)
}

const MobileNavigation = (props) => {
	return (
		<Disclosure.Panel className="h-screen md:hidden id=anim">
			<div>
				<div className="flex justify-center items-center space-y-1 bg-farm-green bg-cover bg-center w-[100%] h-[100%] z-10 sidebar">
					<div className="grid p-20 text-center gird-cols-1">
						{navigation.map((item) => {
							const { name, href, ...props } =
								getNavListProps(item)
							return (
								<Disclosure.Button
									key={name}
									as="a"
									href={href}
									className={classNames(
										"inline-block align-middle text-white font-medium text-4xl  hover:text-farm-yellow",
										"block px-3 py-5 font-heading text-base font-medium w-[100vw]",
									)}
									{...props}
								>
									{name}
								</Disclosure.Button>
							)
						})}
					</div>
				</div>
			</div>
		</Disclosure.Panel>
	)
}

const Navbar = () => {
	return (
		<Disclosure as="nav" id="navbar" className="z-50">
			{({ open }) => (
				<>
					<div className="flex items-center justify-end w-full h-16 px-2 mx-auto bg-center bg-no-repeat md:px-0 md:justify-center md:w-11/12 lg:w-5/6 md:my-auto md:h-32 bg-fill navbar-responsive">
						<div className="flex items-center justify-center">
							<div className="flex items-center justify-start md:hidden rounded-lg bg-[#da9d5e] border-[#5a3e2a] border-2 hamburger">
								{/* Mobile menu button */}
								<Disclosure.Button className="inline-flex items-center justify-start w-full p-1 text-center rounded-md text-farm-black hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="sr-only">
										Open main menu
									</span>
									{open ? (
										<XIcon
											className="block w-8 h-8 text-white"
											aria-hidden="true"
										/>
									) : (
										<MenuIcon
											className="block w-8 h-8 text-white"
											aria-hidden="true"
										/>
									)}
								</Disclosure.Button>
							</div>
							<DesktopNavbar />
						</div>
					</div>

					<MobileNavigation />
				</>
			)}
		</Disclosure>
	)
}

export default Navbar
