import { LinkButton } from "@hfc/shared/components/Button"
import { FaDiscord, FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa"

const navigation = [
	{
		name: "Discord",
		href:
			process.env.NEXT_PUBLIC_DISCORD_URL ??
			"https://discord.gg/dejEkgCQ44",
		icon: () => <FaDiscord size="1.5rem" />,
	},
	{
		name: "Twitter",
		href:
			process.env.NEXT_PUBLIC_TWITTER_URL ??
			"https://twitter.com/farmerNFT",
		icon: () => <FaTwitter size="1.5rem" />,
	},
	{
		name: "Facebook",
		href:
			process.env.NEXT_PUBLIC_FACEBOOK_URL ??
			"https://www.facebook.com/farmerNFT",
		icon: () => <FaFacebook size="1.5rem" />,
	},
	// {
	// 	name: "Opensea",
	// 	href: process.env.NEXT_PUBLIC_OPENSEA_URL ?? "/",
	// 		icon: () => <FaDiscord />,
	// },
	{
		name: "Instagram",
		href:
			process.env.NEXT_PUBLIC_INSTAGRAM_URL ??
			"https://www.instagram.com/farmer_nft/",
		icon: () => <FaInstagram size="1.5rem" />,
	},
]

const Footer = () => {
	return (
		<footer className="bg-white border-t-8 border-t-black">
			<div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
				<div className="flex justify-center space-x-6 md:order-2">
					{navigation.map((item) => (
						<a
							key={item.name}
							href={item.href}
							className="text-gray-400 hover:text-gray-500"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="sr-only">{item.name}</span>
							<item.icon aria-hidden="true" />
						</a>
					))}
				</div>
				<div className="mt-8 md:mt-0 md:order-1">
					<p className="text-base text-center text-gray-400">
						&copy; 2021 HFC. All rights reserved.
					</p>
				</div>
				<div className="z-50 mt-8 md:mt-0 md:order-1">
					<p className="text-base text-center text-gray-400">
						<LinkButton
							href={"/privacy-policy"}
							variant={"transparent"}
							target={"_blank"}
						>
							Privacy Policy
						</LinkButton>{" "}
						|{" "}
						<LinkButton
							href={"/terms"}
							variant={"transparent"}
							target={"_blank"}
						>
							Terms &amp; Conditions
						</LinkButton>
					</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer
