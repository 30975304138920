import { NextSeoProps } from "next-seo"
import { SEOProps } from "@hfc/shared/components/SEO/SEO"

const twitterSite = "https://twitter.com/farmerNFT"
const twitterHandle = twitterSite?.split("twitter.com/")?.[1] ?? ""

const fallbackTwitterCardType: NextSeoProps["twitter"]["cardType"] = "summary"

const getTwitterSEOProps = (props: SEOProps) => {
	const cardType = props?.twitter?.cardType ?? fallbackTwitterCardType

	const twitterSEOProps: NextSeoProps = {
		twitter: {
			handle: twitterHandle,
			site: twitterSite,
			cardType,
		},
	}

	return twitterSEOProps
}

export default getTwitterSEOProps
