import type { AppProps, NextWebVitalsMetric } from "next/app"
import React, { useEffect, useState } from "react"

import { DefaultSEO } from "@hfc/shared/components/SEO/SEO"
import { GoogleTagManagerIframe } from "@hfc/shared/components/SEO/GoogleTagManager"

import Header from "components/layout/Header"
import Footer from "components/layout/Footer"

import isProduction from "@hfc/shared/utils/isProduction"

// Tailwind
import "@hfc/shared/styles/globals.css"
import "@hfc/shared/styles/website.css"

const CustomApp = ({ Component, pageProps }: AppProps) => {

	return (
		<>
			<GoogleTagManagerIframe
				containerId={
					process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID
				}
			/>
			<DefaultSEO asPath="/" />
			<div className="relative min-h-screen overflow-x-hidden text-white bg-farm-black">
				<Header />
				<main>
					<Component {...pageProps} />
				</main>
				<Footer />
			</div>
		</>
	)
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
	if (isProduction()) console.info(metric)
}

export default CustomApp
