import { NextSeoProps } from "next-seo"
import { OpenGraphMedia } from "next-seo/lib/types"
import { SEOProps } from "@hfc/shared/components/SEO/SEO"

const openGraphSiteName = "HFC"

const fallbackOpenGraphImages: OpenGraphMedia[] = [
	{
		url: `${process.env.NEXT_PUBLIC_HOST}/images/farm-social.jpg`,
		width: 800,
		height: 800,
		alt: "Honest Farmer Club",
		type: "website",
		secureUrl: `${process.env.NEXT_PUBLIC_HOST}/images/farm-social.jpg`,
	},
]

const getOpenGraphLocaleFromLanguage = (language: string) => {
	switch (language) {
		case "de":
			return "de_DE"
		default:
			return "en_US"
	}
}

const getOpenGraphSEOProps = (
	props: SEOProps,
	canonical: string,
	language = "en",
) => {
	const { title, description } = props

	const images = props?.openGraph?.images ?? fallbackOpenGraphImages

	const openGraphSEOProps: NextSeoProps = {
		openGraph: {
			url: canonical,
			title: title,
			description: description,
			images,
			site_name: openGraphSiteName,
			locale: getOpenGraphLocaleFromLanguage(language),
		},
	}

	return openGraphSEOProps
}

export default getOpenGraphSEOProps
