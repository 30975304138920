/* eslint-disable jsx-a11y/anchor-is-valid */

import Link from "next/link"
import classNames from "@hfc/shared/utils/css/classNames"

type ButtonVariant =
	| "primary"
	| "green"
	| "disableGreen"
	| "disable"
	| "secondary"
	| "grey"
	| "transparent"
	| "danger"
	| "red"
	| "transparentZoom"
	| "greyZoom"
	| "cowActive"
	| "cowDisabled"
	| "socials"

const getVariantSpecificButtonProps = (
	variant: ButtonVariant,
): React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
> => {
	switch (variant) {
		case "primary":
			return {
				className:
					"bg-[#3daf49] font-[Gluten] py-3 border-0 text-white hover:bg-[#2b7c33] hover:shadow-lg hover:shadow-[#1a1818]/50 hover:brightness-[110%]",
			}
		case "green":
			return {
				className:
					"bg-[#3daf49] font-[Gluten] text-white py-3 hover:brightness-[110%] hover:shadow-md hover:shadow-[#1a1818] border-0",
			}
		case "disableGreen":
			return {
				className:
					"bg-[#2b7c33] font-[Gluten] border-0 py-3 text-white hover:bg-[#2b7c33]",
			}
		case "grey":
			return {
				className:
					"bg-farm-grey text-gray-900 font-[Gluten] py-3 hover:bg-farm-dark-grey hover:text-white disabled:bg-farm-dark-grey disabled:text-white border-0",
			}

		case "transparent":
			return {
				className:
					"bg-transparent border-none font-[Gluten] py-3 border-0",
			}
		case "greyZoom":
			return {
				className:
					"bg-farm-grey text-gray-900 pt-1 hover:bg-farm-dark-grey hover:text-white disabled:bg-farm-dark-grey disabled:text-white border-0",
			}

		case "transparentZoom":
			return {
				className: "bg-transparent border-none pt-1 border-0",
			}

		case "socials":
			return {
				className: "bg-transparent border-none pt-1 border-0",
			}

		case "danger":
			return {
				className:
					"bg-transparent border-none font-[Gluten] py-3 border-0",
			}

		case "red":
			return {
				className:
					"bg-red-700 text-white hover:bg-red-600 font-[Gluten] py-3 border-0",
			}
		case "cowActive":
			return {
				className:
					"bg-[#3daf49] font-[Gluten] border-0 text-white hover:bg-[#2b7c33] hover:shadow-lg hover:shadow-[#1a1818]/50 hover:brightness-[110%]",
			}

		case "cowDisabled":
			return {
				className:
					"bg-farm-grey text-gray-900 font-[Gluten] hover:bg-farm-dark-grey hover:text-white disabled:bg-farm-dark-grey disabled:text-white border-0",
			}

		case "secondary":
		default:
			return {
				className:
					"bg-farm-yellow text-gray-900 hover:bg-yellow-400 font-[Gluten] py-3 border-0",
			}
	}
}

interface ButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	variant?: ButtonVariant
}

const defaultClassName =
	"inline-block border border-transparent rounded-md text-base font-medium hover:bg-opacity-100 border-black border-2 disabled:cursor-no-drop"

const Button = ({ children, variant = "secondary", ...props }: ButtonProps) => {
	const variantProps = getVariantSpecificButtonProps(variant)
	const className = classNames(
		defaultClassName,
		variantProps.className ?? "",
		props.className ?? "",
	)
	return (
		<button {...props} className={className}>
			{children}
		</button>
	)
}

interface LinkButtonProps extends ButtonProps {
	href: string
	linkProps?: Record<string, any>
	target?: string
}

export const LinkButton = ({
	href,
	linkProps,
	target = "_self",
	...props
}: LinkButtonProps) => {
	return (
		<Link href={href}>
			<a {...linkProps} target={target}>
				<Button {...props} />
			</a>
		</Link>
	)
}

export default Button
