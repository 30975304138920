import { NextSeoProps } from "next-seo"

import getOpenGraphSEOProps from "./smm/openGraph"
import getTwitterSEOProps from "./smm/twitter"

import { SEOProps } from "@hfc/shared/components/SEO/SEO"
import getPageTypeSpecificSEOProps from "./pages/getPageTypeSpecificSEOProps"

const NEXT_PUBLIC_HOST =
	process.env.NEXT_PUBLIC_HOST ?? process.env.NEXT_PUBLIC_VERCEL_URL
		? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
		: "http://localhost:3000"

const prependHostToPath = (path: string) => `${NEXT_PUBLIC_HOST}${path}`

const getNextSEOProps = (props: SEOProps) => {
	const { asPath, noindex = false } = props

	const canonical = prependHostToPath(asPath.split("?")[0])

	const pageSpecificSEOProps = getPageTypeSpecificSEOProps(props)

	const openGraphSEOProps = getOpenGraphSEOProps(
		{ ...props, ...pageSpecificSEOProps },
		canonical,
	)
	const twitterSEOProps = getTwitterSEOProps({
		...props,
		...pageSpecificSEOProps,
	})

	const nextSEOProps: NextSeoProps = {
		canonical,
		noindex,
		...openGraphSEOProps,
		...twitterSEOProps,
		...pageSpecificSEOProps,
	}

	return nextSEOProps
}

export default getNextSEOProps
