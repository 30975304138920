import { NextSeo, DefaultSeo as DefaultNextSeo, NextSeoProps } from "next-seo"

import getNextSEOProps from "@hfc/shared/utils/seo/getNextSEOProps"

const SEO = (props: SEOProps) => {
	const nextSeoProps = getNextSEOProps(props)

	return <NextSeo {...nextSeoProps} />
}

export interface SEOProps extends DefaultSEOProps {
	asPath: string
	pageType?: string
	appendDefaultTitle?: boolean
}

export default SEO

export const DefaultSEO = (props: DefaultSEOProps) => {
	const nextSeoProps = getNextSEOProps(props)

	return <DefaultNextSeo {...nextSeoProps} />
}

interface DefaultSEOProps extends NextSeoProps {
	asPath: string
}
